exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-medien-js": () => import("./../../../src/pages/medien.js" /* webpackChunkName: "component---src-pages-medien-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-shopify-product-handle-js": () => import("./../../../src/pages/shop/{ShopifyProduct.handle}.js" /* webpackChunkName: "component---src-pages-shop-shopify-product-handle-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-pages-twitch-js": () => import("./../../../src/pages/twitch.js" /* webpackChunkName: "component---src-pages-twitch-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-versandkosten-js": () => import("./../../../src/pages/versandkosten.js" /* webpackChunkName: "component---src-pages-versandkosten-js" */),
  "component---src-pages-warenkorb-js": () => import("./../../../src/pages/warenkorb.js" /* webpackChunkName: "component---src-pages-warenkorb-js" */),
  "component---src-pages-widerrufsbelehrung-js": () => import("./../../../src/pages/widerrufsbelehrung.js" /* webpackChunkName: "component---src-pages-widerrufsbelehrung-js" */)
}

